<template>
  <Component
    :is="buttonComponent"
    class="button-point"
    :to="route"
    :class="color"
    :type="buttonComponent === 'button' ? 'button' : undefined"
  >
    <div class="label">
      {{ capitalize(label) }}
      <div class="deco" />
    </div>
    <div class="points">+{{ points }} pts</div>
  </Component>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'
import { capitalize } from '~/utils/formatHelper'

const props = withDefaults(
  defineProps<{
    label?: string
    route?: string
    tag?: string
    points?: number
    color?: string
  }>(),
  {
    label: 'ajouter un contenu',
    route: undefined,
    tag: 'button',
    points: 0,
    color: undefined,
  },
)

const buttonComponent = computed(() => {
  if (props.route) {
    return NuxtLink
  }
  return props.tag
})
</script>

<style lang="scss" scoped>
.button-point {
  position: relative;
  display: inline-flex;
  height: 6rem;
  padding: 0;
  color: #fff;
  font-family: inherit;
  line-height: 6rem;
  text-decoration: inherit;
  background: $blue;
  border: 0;
  cursor: pointer;
  appearance: none;

  &.white {
    background: $darkBlue;

    .label {
      color: $blue;
      background: #fff;

      &::after {
        border-color: transparent transparent $darkBlue transparent;
      }
    }

    .deco {
      &::before {
        border-color: #fff transparent transparent;
      }

      &::after {
        border-color: transparent transparent $darkBlue transparent;
      }
    }
  }

  .label {
    position: relative;
    height: 100%;
    padding: 0 3rem 0 2rem;
    font-size: 1.6rem;
    font-weight: 500;
    background: $secondaryBlue;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-width: 0 0 6rem 1rem;
      border-style: solid;
      border-color: transparent transparent $blue transparent;
    }
  }

  .points {
    height: 100%;
    padding: 0 2rem;
    font-size: 1.8rem;
    font-weight: bold;
  }

  .deco {
    position: absolute;
    top: 0;
    right: -0.8rem;
    z-index: 1;
    width: 2.5rem;
    height: 100%;
    background: linear-gradient(80deg, $blue, $white);
    opacity: 0.26;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &::before {
      left: 0;
      border-width: 6rem 1.4rem 0 0;
      border-color: $secondaryBlue transparent transparent transparent;
    }

    &::after {
      right: 0;
      border-width: 0 0 6rem 1.5rem;
      border-color: transparent transparent $blue transparent;
    }
  }
}

@media screen and (max-width: 700px) {
  .button-point {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    line-height: 1.3;

    .label {
      display: flex;
      flex-grow: 1;
      align-items: center;
      height: 6rem;
      padding: 0 4rem 0 3rem;
      font-size: 1.6rem;

      &::after {
        border-width: 0 0 6rem 1rem;
      }
    }

    .points {
      display: flex;
      align-items: center;
      padding: 0 4rem;
      font-size: 1.8rem;
    }

    .deco {
      right: -0.8rem;
      width: 2.5rem;

      &::before {
        border-width: 6rem 1.4rem 0 0;
      }

      &::after {
        border-width: 0 0 6rem 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .button-point {
    width: 100%;

    .label {
      padding: 0 2rem;
      font-size: 1.4rem;

      &::after {
        border-width: 0 0 6rem 1rem;
      }
    }

    .points {
      padding: 0 2rem;
      font-size: 1.6rem;
    }

    .deco {
      right: -0.8rem;
      width: 2.5rem;

      &::before {
        border-width: 6rem 1.4rem 0 0;
      }

      &::after {
        border-width: 0 0 6rem 1.5rem;
      }
    }
  }
}
</style>
